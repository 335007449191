<template>
  <div class="auth-wrapper auth-v1 px-2 scl-login-page">
    <div class="auth-inner py-5">
      <!-- Login-->
      <b-col md="4" />
      <b-col>
        <b-card class="mb-0 scl-login-card width-400">
          <!-- Brand logo-->
          <b-link class="brand-logo scl-logo mb-2">
            <b-img
              ref="previewEl"
              rounded
              src="@/assets/images/logo/logo.png"
              height="80"
            />
          </b-link>
          <!-- /Brand logo-->
          <b-card-title
            class="mb-1 font-weight-bold"
            title-tag="h4"
          >
            Reset your password
          </b-card-title>
          <b-card-text class="mb-2">
            Please enter your new password and confirm it.
          </b-card-text>

          <!-- form -->
          <validation-observer
            ref="resetPasswordForm"
            #default="{invalid}"
          >
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent="resetPassword"
            >
              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">New Password</label>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  vid="Password"
                  rules="required|password"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      v-model="newPw"
                      :state="errors.length > 0 ? false:null"
                      class="form-control-merge"
                      type="password"
                      name="new-password"
                      placeholder="New Password"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">Confirm Password</label>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Password Confirm"
                  rules="required|confirmed:Password"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      v-model="confirmPw"
                      :state="errors.length > 0 ? false:null"
                      class="form-control-merge"
                      type="password"
                      name="confirm-password"
                      placeholder="Confirm Password"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- submit buttons -->
              <b-button
                type="submit"
                variant="primary"
                block
                :disabled="invalid"
              >
                Reset Password
              </b-button>
            </b-form>
          </validation-observer>
        </b-card>
      </b-col>
      <b-col md="4" />
    <!-- /Login-->
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required, confirmed, password,
} from '@validations'
import {
  BCol, BCard, BLink, BFormGroup, BFormInput, BInputGroup, BCardText, BCardTitle, BForm, BButton, VBTooltip, BImg,
} from 'bootstrap-vue'
import axios from 'axios'
import store from '@/store/index'
import swal from 'sweetalert2'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BCard,
    BCol,
    BLink,
    BImg,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BCardText,
    BCardTitle,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      status: '',
      newPw: '',
      confirmPw: '',
      sideImg: require('@/assets/images/pages/login-v2.svg'),

      // validation rules
      required,
      password,
      confirmed,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    showMessage(message, inError) {
      swal.fire({
        title: inError ? 'Error' : 'Success!',
        text: message,
        icon: inError ? 'error' : 'success',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    resetPassword() {
      if (this.$route.query.token === undefined || this.$route.query.token === '') {
        this.showMessage('Reset link is invalid, please use the forgotten password link for request a new reset link', true)
        window.location = 'https://portal.supplyconstruction.co.uk/login'
        // this.$router.push('/login')
        return
      }

      this.$refs.resetPasswordForm.validate().then(success => {
        if (success) {
          const data = JSON.stringify({ token: this.$route.query.token, password: this.confirmPw })
          const config = {
            headers: {
              'Content-Type': 'application/json',
            },
          }

          axios.post(`${this.$siteUrl}password_reset/confirm/`, data, config)
            .then(response => {
              if (response.data.status === 'OK') {
                this.showMessage('Your password has been reset', false)
                window.location = 'https://portal.supplyconstruction.co.uk/login'
                // this.$router.push('/login')
              }
            })
            .catch(e => {
              let message = ''
              if (e.response.data.status === 'notfound' || e.response.data.status === 'expired') {
                message = 'Reset link as expired, use the forgotton password link to get a new one'
              } else {
                message = e.response.data.status
              }
              this.showMessage(message, true)
              window.location = 'https://portal.supplyconstruction.co.uk/login'
              // this.$router.push('/login')
            })
        } else {
          this.showMessage('Passwords do not match or do not match requirements', true)
        }
      })
    },
  },
}
</script>

<style scoped>
.scl-login-page {
  background: linear-gradient(90deg, #06B39A 1.88%, #017E6C 59.09%, #004A4D 95.76%);
  height:100%;
}
.scl-login-box {
  height:100%;
}
.scl-login-card{
  margin: 0 auto; /* Added */
  float: none; /* Added */
  margin-bottom: 10px; /* Added */
}
.scl-logo {
  display: flex;
  justify-content: center;
}
</style>
